import translate from "../../i18n/translate";

export const coreValuesScopingCall = [
  {
    count: "01",
    heading: translate("scoping-call-heading1"),
    description: translate("scoping-call-description1"),
  },
  {
    count: "02",
    heading: translate("scoping-call-heading2"),
    description: translate("scoping-call-description2"),
  },
  {
    count: "03",
    heading: translate("scoping-call-heading3"),
    description: translate("scoping-call-description3"),
  },
  {
    count: "04",
    heading: translate("scoping-call-heading4"),
    description: translate("scoping-call-description4"),
  },
  {
    count: "05",
    heading: translate("scoping-call-heading5"),
    description: translate("scoping-call-description5"),
  },
];
