// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "ScopingCallMain-module--aboutMainCircleBlurBlue--Hb6fr";
export var application = "ScopingCallMain-module--application--mAC0C";
export var applicationHeading = "ScopingCallMain-module--applicationHeading--OtWj8";
export var applicationMap = "ScopingCallMain-module--applicationMap--gtjmu";
export var approach = "ScopingCallMain-module--approach--VBamz";
export var backgroundCircles = "ScopingCallMain-module--backgroundCircles--XZWCZ";
export var banner = "ScopingCallMain-module--banner--GYjy-";
export var benefits = "ScopingCallMain-module--benefits--YIfTU";
export var bottomButton = "ScopingCallMain-module--bottomButton--bBJ32";
export var careerBtnApplyNow = "ScopingCallMain-module--careerBtnApplyNow--xRZEG";
export var careers = "ScopingCallMain-module--careers--c4X8q";
export var caseChallengeImage = "ScopingCallMain-module--caseChallengeImage--42GhL";
export var caseTestimonialImage = "ScopingCallMain-module--caseTestimonialImage--4WXNv";
export var contact = "ScopingCallMain-module--contact--XNz83";
export var contactButton = "ScopingCallMain-module--contactButton--37UYT";
export var contactOutlineCircle = "ScopingCallMain-module--contactOutlineCircle--8h0-k";
export var contactOutlineCircleBenfits = "ScopingCallMain-module--contactOutlineCircleBenfits--wezsH";
export var coreValue = "ScopingCallMain-module--coreValue--VECcC";
export var coreValueColumbLeft = "ScopingCallMain-module--coreValueColumbLeft--fQsUR";
export var coreValueColumbRight = "ScopingCallMain-module--coreValueColumbRight--tW-fp";
export var coreValueItem = "ScopingCallMain-module--coreValueItem--MnLhF";
export var coreValueItemBox = "ScopingCallMain-module--coreValueItemBox--VoeYn";
export var coreValuesHeading = "ScopingCallMain-module--coreValuesHeading--qROKx";
export var description = "ScopingCallMain-module--description--9EJo1";
export var flowContainer = "ScopingCallMain-module--flowContainer---6QmX";
export var flowItem = "ScopingCallMain-module--flowItem--96li2";
export var flowItemDay = "ScopingCallMain-module--flowItemDay--AoZnv";
export var flowItemDayMobile = "ScopingCallMain-module--flowItemDayMobile--meVen";
export var flowItemDayMobileContainer = "ScopingCallMain-module--flowItemDayMobileContainer--W2yaY";
export var flowItemMobile = "ScopingCallMain-module--flowItemMobile--Y66pe";
export var flowLeftContainer = "ScopingCallMain-module--flowLeftContainer--GwjVe";
export var flowRightContainer = "ScopingCallMain-module--flowRightContainer--E8Kdz";
export var flowRightContainerMobile = "ScopingCallMain-module--flowRightContainerMobile--40dj0";
export var flowStop = "ScopingCallMain-module--flowStop--89w+R";
export var followUs = "ScopingCallMain-module--followUs--ujjDL";
export var gdnPromotePic = "ScopingCallMain-module--gdnPromotePic--niZXG";
export var greenColor = "ScopingCallMain-module--greenColor--0Kb-2";
export var heading = "ScopingCallMain-module--heading--cp4Ra";
export var heroBackgroundCircleLeft = "ScopingCallMain-module--heroBackgroundCircleLeft--g6W72";
export var heroBackgroundInnerCircleGreen = "ScopingCallMain-module--heroBackgroundInnerCircleGreen--zygCB";
export var heroBackgroundInnerCircleLeft = "ScopingCallMain-module--heroBackgroundInnerCircleLeft--7jDff";
export var heroBackgroundInnerCircleLeft2 = "ScopingCallMain-module--heroBackgroundInnerCircleLeft2--PVRgD";
export var heroBackgroundInnerCircleLeftBenfits = "ScopingCallMain-module--heroBackgroundInnerCircleLeftBenfits--mY55d";
export var heroBackgroundInnerCircleOverflowText = "ScopingCallMain-module--heroBackgroundInnerCircleOverflowText--DDQOE";
export var heroBackgroundInnerCircleRight = "ScopingCallMain-module--heroBackgroundInnerCircleRight--718th";
export var heroBannerMainContainer = "ScopingCallMain-module--heroBannerMainContainer--spX6+";
export var heroWhiteBlurCirce = "ScopingCallMain-module--heroWhiteBlurCirce--Zfn0v";
export var igImageGroup1 = "ScopingCallMain-module--igImageGroup1--4wTBE";
export var igImageGroup1Item = "ScopingCallMain-module--igImageGroup1Item--MAXba";
export var igImageGroup2 = "ScopingCallMain-module--igImageGroup2--ubPuo";
export var igImageGroup2Btm = "ScopingCallMain-module--igImageGroup2Btm--9U64u";
export var igImageGroup2BtmItem = "ScopingCallMain-module--igImageGroup2BtmItem--7l87u";
export var igImageGroup2Item = "ScopingCallMain-module--igImageGroup2Item--j49sS";
export var inNumberColumbLeft = "ScopingCallMain-module--inNumberColumbLeft--Fv42X";
export var inNumberColumbRight = "ScopingCallMain-module--inNumberColumbRight--QO2yA";
export var inNumbersImage = "ScopingCallMain-module--inNumbersImage--XpPwf";
export var inNumbersItem = "ScopingCallMain-module--inNumbersItem--Kh+SF";
export var innumbers = "ScopingCallMain-module--innumbers--Qkv2P";
export var insights = "ScopingCallMain-module--insights--qA+v+";
export var instaContainer = "ScopingCallMain-module--instaContainer--3F5ns";
export var instaLeftBox = "ScopingCallMain-module--instaLeftBox--A9pU4";
export var instaRightBox = "ScopingCallMain-module--instaRightBox--qpaKX";
export var introDescriptionButtonSection = "ScopingCallMain-module--introDescriptionButtonSection--bx-9G";
export var introImageSection = "ScopingCallMain-module--introImageSection--WYU8D";
export var introSectionCircleItem = "ScopingCallMain-module--introSectionCircleItem--7uqhG";
export var introSectionContainer = "ScopingCallMain-module--introSectionContainer--f5nvS";
export var introSectionText = "ScopingCallMain-module--introSectionText--LD1oH";
export var keyTopicsImage = "ScopingCallMain-module--keyTopicsImage--1TmNG";
export var knowUs = "ScopingCallMain-module--knowUs--+lK9e";
export var knowUsImage = "ScopingCallMain-module--knowUsImage--zhKlt";
export var knowUsText = "ScopingCallMain-module--knowUsText--Fx1NP";
export var pillars = "ScopingCallMain-module--pillars--5lEaj";
export var reportBtn = "ScopingCallMain-module--reportBtn--oZzfC";
export var reportBtnGerman = "ScopingCallMain-module--reportBtnGerman--0MGlM";
export var singleBenefitTextBox = "ScopingCallMain-module--singleBenefitTextBox--Kkk5e";
export var singleCaseChallenge = "ScopingCallMain-module--singleCaseChallenge--SgDRB";
export var singleCaseChallengeFirst = "ScopingCallMain-module--singleCaseChallengeFirst--qLSu-";
export var singleCaseContentBlock = "ScopingCallMain-module--singleCaseContentBlock--+f3hY";
export var singleCaseImageBox = "ScopingCallMain-module--singleCaseImageBox--m3Aqg";
export var singleCaseImageBoxForth = "ScopingCallMain-module--singleCaseImageBoxForth--I12bP";
export var singleCaseImageBoxSecond = "ScopingCallMain-module--singleCaseImageBoxSecond--2kN9F";
export var singleCaseSolution = "ScopingCallMain-module--singleCaseSolution--GQKHS";
export var singleCaseSolutionMobile = "ScopingCallMain-module--singleCaseSolutionMobile--OazY-";
export var singleCaseTestimonial = "ScopingCallMain-module--singleCaseTestimonial--RsnLE";
export var singleCaseTestimonialInner = "ScopingCallMain-module--singleCaseTestimonialInner--0lkoH";
export var singleCaseTextBox = "ScopingCallMain-module--singleCaseTextBox--KYy6T";
export var singleCaseTextBoxLast = "ScopingCallMain-module--singleCaseTextBoxLast--Ma1sp";
export var singleCaseTextFirst = "ScopingCallMain-module--singleCaseTextFirst--5+897";
export var testimonialBody = "ScopingCallMain-module--testimonialBody--gNIri";
export var testimonialContent = "ScopingCallMain-module--testimonialContent---JzhM";
export var testimonialName = "ScopingCallMain-module--testimonialName--gC7Mp";
export var torchWrapper = "ScopingCallMain-module--torchWrapper--6vqOO";
export var verticalLine = "ScopingCallMain-module--verticalLine--Gynkx";
export var verticalLineMobile = "ScopingCallMain-module--verticalLineMobile--vH1BG";
export var yourBenefits = "ScopingCallMain-module--yourBenefits--TG1F5";
export var yourBenefitsIcon = "ScopingCallMain-module--yourBenefitsIcon--lS0Hj";
export var yourBenefitsInner = "ScopingCallMain-module--yourBenefitsInner--og9SI";
export var yourBenefitsItem = "ScopingCallMain-module--yourBenefitsItem--znCPq";