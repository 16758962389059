import React from "react";
import * as styles from "./ScopingCallMain.module.scss";
import { UseWindowSize } from "../hooks/UseWindowSize";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import scopingCallMain from "../../images/scopingCallMain.jpg";
import scopingCallFirst from "../../images/scopingCallFirst.jpg";
import scopingCallSecond from "../../images/scopingCallSecond.jpg";
import scopingCallThird from "../../images/scopingCallThird.jpg";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import cx from "classnames";

import ContactForm from "../Shared/ContactUiComponents/ContactForm";
import translate from "../../i18n/translate";
import { coreValuesScopingCall } from "./ScopingCall.helper";

const ScopingCallMain = () => {
  const width = UseWindowSize();
  return (
    <div>
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={translate("scoping-call-heading")}
          description={translate("scoping-call-description")}
          homeBackground={scopingCallMain}
          date=""
          alt="Scoping call page"
        />
      ) : (
        <InnerWrapper>
          <HeroBanner
            heading={translate("scoping-call-heading")}
            description={translate("scoping-call-description")}
            homeBackground={scopingCallMain}
            date=""
            isImageCentered={true}
          />
        </InnerWrapper>
      )}

      <InnerWrapper>
        <div className={styles.singleCaseContentBlock}>
          <div
            className={cx(
              styles.singleCaseSolution,
              styles.singleCaseSolutionMobile
            )}
          >
            <div
              className={cx(
                styles.singleCaseImageBox,
                styles.singleCaseImageBoxSecond
              )}
            >
              <img
                src={scopingCallFirst}
                alt="Chalenges"
                className={styles.caseChallengeImage}
              />
            </div>

            <div className={styles.singleCaseTextFirst}>
              <div>
                <h2>
                  <span>.</span>
                  {translate("scoping-call-optimization-title")}
                </h2>

                <p>{translate("scoping-call-optimization-description")}</p>
              </div>
            </div>
          </div>

          <div className={styles.coreValue}>
            <div className={styles.coreValueColumbRight}>
              <h2 className={styles.coreValuesHeading} style={{ width: "90%" }}>
                <span>.</span>
                {translate("scoping-call-procedure-title")}

                <br></br>
              </h2>
              <div className={styles.coreValueItemBox}>
                {coreValuesScopingCall?.map((item, index) => {
                  return (
                    <div className={styles.coreValueItem} key={index}>
                      <h5>{item?.count}</h5>
                      <p className={styles.greenColor}>{item?.heading}</p>
                      <p className={styles.description}>{item?.description}</p>
                    </div>
                  );
                })}
              </div>
              <CircleBlur
                initialScale={0.1}
                initialOpacity={0}
                animateScale={1.8}
                animateOpacity={0.15}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.aboutMainCircleBlurBlue}
              />
              <CircleOutline
                isBlurVisible={true}
                initialScale={0.3}
                animateScale={1}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroBackgroundCircleLeft}
              />
              <CircleBlur
                initialScale={0.1}
                initialOpacity={0}
                animateScale={1.8}
                animateOpacity={0.15}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.heroBackgroundInnerCircleLeft}
              />
            </div>
            <div className={styles.coreValueColumbLeft}>
              <img
                src={scopingCallSecond}
                alt="Settlements on two sides of a river with structured ports showing the clear foundation globaldatanet is built on"
                className={styles.keyTopicsImage}
              />
            </div>
          </div>

          <div
            className={cx(
              styles.singleCaseSolution,
              styles.singleCaseSolutionMobile
            )}
          >
            <div
              className={cx(
                styles.singleCaseImageBox,
                styles.singleCaseImageBoxSecond
              )}
            >
              <img
                src={scopingCallThird}
                alt="Chalenges"
                className={styles.caseChallengeImage}
              />
            </div>

            <div className={styles.singleCaseTextBox}>
              <div>
                <h2>
                  <span>.</span>
                  {translate("scoping-call-who-are-we-title")}{" "}
                </h2>

                <p>{translate("scoping-call-who-are-we-description")}</p>
              </div>
            </div>
          </div>
        </div>

        <ContactForm heading={translate("scoping-call-contact-heading")} />
      </InnerWrapper>
    </div>
  );
};

export default ScopingCallMain;
