import React from "react";
import Layout from "../components/Layout/Layout";
import ScopingCallMain from "../components/ScopingCallUiComponents/ScopingCallMain";
import { SEO } from "../components/seo";

const ScopingCall = () => {
  return (
    <Layout location="/blog">
      <SEO
        title="Scoping call"
        description="Find out the rules related to personal data protection on the globaldatanet site."
        type="website"
      />
      <ScopingCallMain />
    </Layout>
  );
};

export default ScopingCall;
